import React, { useRef } from "react";
import FacephiLogo from "./assets/facephi.svg";
import 'bootstrap/dist/css/bootstrap.min.css';
import { replaceChar } from './utils';
import { saveAs } from 'file-saver';


const encryptXor = (text, key) => {
  var enc = new TextEncoder(); // always utf-8

  var textArr = enc.encode(text);
  var result = new Uint8Array(text.length);

  for (var i = 0; i < textArr.length; i++) {
      result[i] = textArr[i] ^ key.charCodeAt(i % key.length)
  }

  return result;
}

const decryptXor = (arrBuf, key) => {
  var resultArr = new Uint8Array(arrBuf.length);

  for (var i = 0; i < arrBuf.length; i++) {
    resultArr[i] = arrBuf[i] ^ key.charCodeAt(i % key.length);
  }

  var dec = new TextDecoder("utf-8");

  return dec.decode(resultArr);
}


function a2hex(arrBuf) {
  return [...new Uint8Array(arrBuf)]
    .map(x => x.toString(16).padStart(2, '0'))
    .join('');
}
function hex2a(hexx) {
  return new Uint8Array(hexx.match(/../g).map(h=>parseInt(h,16)))
}


const App = () => {
  const rawLicense = useRef();
  const outputLicense = useRef();
  const generateButton = useRef();

  const providerLicense = useRef();
  const customerName = useRef();
  const urlBasePath = useRef();
  const expirationDate = useRef();
  const comments = useRef();

  const XOR_KEY = 'FacePhi';

  const generateLicense = () => { 
    return {
      customer: "NA",
      documentType: "LicenseEnvelope",
      license: {
        comments: "NA",
        dateEnd: expirationDate.current.value,
        extraData: a2hex(encryptXor(JSON.stringify(plainEnvolveTextLicense()), XOR_KEY)).toUpperCase(),
        licenseDocument: true,
        licenseFacial: false,
        licenseTokenDocument: true,
        logging: false,
        os: "Any",
        packageName: urlBasePath.current.value,
        engineType: document.querySelector('input[name="engine-type"]:checked').value,
        product: "SelphID",
        version: "1.0"
      },
      provider: "FacePhi"
    }
  }

  const plainTextLicense = () => {
    return {
      customer: "NA",
      documentType: "LicenseEnvelope",
      license: {
        comments: "NA",
        dateEnd: expirationDate.current.value,
        licenseDocument: providerLicense.current.value,
        packageName: urlBasePath.current.value,
        engineType: document.querySelector('input[name="engine-type"]:checked').value,
        product: "SelphID",
        version: "1.0"
      },
      provider: "FacePhi"
    }
  }

  const plainEnvolveTextLicense = () => {
    return {
      licenseDocument: providerLicense.current.value,
      licenseTokenDocument: JSON.stringify(plainTextLicense()),
      logging: false,
      engineType: document.querySelector('input[name="engine-type"]:checked').value
    }    
  }
  return (
    <div className="App">
      <header className="bg-primary">
        <div className="container p-3 d-flex align-items-center justify-content-between">
          <img alt="Facephi" src={FacephiLogo}></img>
        </div>
      </header>
      <main className="container row">
        <section className="col-sm-6 align-items-stretch">
          <fieldset className="form-group">
            <legend>License Web</legend>

            <div className="form-row">
              <label htmlFor="provider-license" className="col">Provider License Key</label>
              <input ref={providerLicense} id="provider-license" className="form-control col" type="text"></input>
            </div>

            <div className="form-row">
              <label htmlFor="url-base-path" className="col">URL Base path</label>
              <input ref={urlBasePath} id="url-base-path" className="form-control col" type="text"></input>
            </div>
            <div className="form-row">
              <label htmlFor="expiration-date" className="col">Expiration Date</label>
              <input ref={expirationDate} id="expiration-date" type="date" className="col"></input>
            </div>


            <div className="form-row">
              <label htmlFor="engine-type" className="col">Engine Type</label>

              <div className="form-check form-check-inline col">
                <input className="form-check-input" type="radio" name="engine-type" id="facephi" value="Facephi" />
                <label className="form-check-label" htmlFor="facephi">Facephi</label>
              </div>
              <div className="form-check form-check-inline col">
                <input className="form-check-input" type="radio" name="engine-type" id="microblink" value="Microblink" defaultChecked />
                <label className="form-check-label" htmlFor="microblink">Microblink</label>
              </div>
            </div>

            <button ref={generateButton} type="button" className="form-row btn btn-dark" onClick={() => {
              try {
                const outputCurrent = outputLicense.current;
                //Generate file name
                const corpPrefix = "FPhi_";
                var urlString = urlBasePath.current.value;
                urlString = replaceChar(urlString);
                const fileName = corpPrefix.concat(urlString, "_WEB.lic");
                
                console.log("Generating license key...");

                const generateKey = a2hex(encryptXor(JSON.stringify(generateLicense()), XOR_KEY))

                outputCurrent.value = generateKey.toString().toUpperCase()
                //Code for saving the license as a file
                var FileSaver = require('file-saver');
                var file = new File([generateKey.toString().toUpperCase()], fileName, {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(file);
              } catch (e) {
                console.log(e)
                alert("Generating Error: The license can not be generated because some field is missing!");
              }

            }}>Generate License</button>

          </fieldset>
        </section>
        <section className="col-sm-6">
          
          <fieldset>
            <legend>Output Base64 license</legend>

            <textarea style={{"width": "100%"}} ref={outputLicense}></textarea>
          </fieldset>
        </section>
      </main>
      <footer className="bg-primary text-white fixed-bottom">
        <div className="container px-3 d-flex justify-content-between align-items-center">
          <small>2023 ©FacePhi. All rights reserved.</small>
          <div className="small font-weight-bold px-3 py-2 bg-warning text-dark">Licensing Tool</div>
        </div>
      </footer>
    </div>
  );
}

export default App;
